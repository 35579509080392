@import '../../../../scss/theme-bootstrap';

.gnav-util-cart {
  a {
    text-decoration: none;
    color: $color-black;
  }
  .gnav-util__icon--cart {
    @include svg-icon-inline-mask('bag.svg');
    width: 20px;
    height: 24px;
    background-color: $color-black;
  }
  &.cart-block--nonempty {
    .gnav-util__icon--cart {
      background-color: $color-black;
    }
  }
  &__count {
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    @include swap_direction(padding, 6px 0 0 1px);
    .cart-block--nonempty & {
      color: $color-white;
    }
  }
  &__content {
    overflow: hidden;
    &-errors {
      @include breakpoint($landscape-up) {
        padding: 0 44px 22px;
        font-size: 14px;
        line-height: get-line-height(12px, 14px);
      }
      ul {
        list-style: none;
        li {
          padding: 5px 0;
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    &-loading {
      display: none;
      .gnav-util-cart.cart-loading & {
        height: 20px;
        display: block;
        padding: 20px 0;
      }
      .loader {
        .gnav-util-cart.cart-loading & {
          display: block;
          opacity: 1;
        }
      }
    }
    &-products {
      display: none;
      font-size: 14px;
      line-height: get-line-height(12px, 12px);
      padding: 25px 0 0;
      height: calc(100% - 90px);
      &-empty-title {
        margin: 12px 0;
      }
      &-empty-copy {
        margin-bottom: 12px;
      }
      &-empty-cta-wrapper {
        display: flex;
        justify-content: center;
        &-item {
          &:first-child {
            margin-#{$rdirection}: 10px;
          }
        }
      }
      &-empty-top {
        display: flex;
        justify-content: center;
        padding-bottom: 12px;
        .icon--icon-beaker {
          height: 45px;
          width: 45px;
        }
      }
      .cart-block--nonempty &,
      .cart-block--added & {
        display: flex;
        flex-direction: column;
        min-height: 200px;
      }
      .cart-block--nonempty.cart-loading & {
        display: none;
      }
      &-no-items {
        display: none;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .cart-block--empty & {
          display: flex;
          padding: 0 0 20px;
          min-height: 200px;
          height: calc(100% - 90px);
        }
        .cart-block--empty.cart-loading &,
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &-details {
        overflow-y: auto;
        line-height: get-line-height(12px, 18px);
      }
      &-bottom {
        padding-bottom: 25px;
        &-subtotal-details {
          padding: 25px 0;
          letter-spacing: 0.12em;
          text-transform: uppercase;
          color: $color-black;
          font-weight: bold;
          border-top: 1px solid $color-light-gray;
          font-family: $font--subheading;
          &-title {
            text-align: $ldirection;
            display: inline;
          }
          &-value {
            text-align: $rdirection;
            display: inline;
            float: $rdirection;
          }
        }
        &-shipping {
          text-align: center;
          padding-bottom: 25px;
        }
        &-checkout {
          a.gnav-util-cart__content-products-checkout-button {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  &.cart-block--empty {
    .gnav-util__icon__cart-count {
      display: none;
    }
  }
}
